
.mybox {
  position: relative;
  /* padding: 10px 20px; */
  background-color: #EEEEEE;
}

.mybox:after {
  content: '';
  position: absolute;
  right: 0px;
  /* left: 25%; */
  /* width: 50%; */
  height: 90%;
  border-right: 1px solid lightgray;
}