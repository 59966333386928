#border-animation{
   width: 0;
   height: 0;
}
.offer-cover-img:hover div{
    content: "";
    background: rgb(255, 255, 255);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100px;
}



.image-responsive{
    width: 312px;
    height: 155px;
}


@media (max-width: 400px) {
    
.image-responsive{
    width: 150px;
    height: 70px;
}

}