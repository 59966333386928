.inputStyle{
    font-size: 16px;
    padding: 10px 20px 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.384) !important;
    /* background-color: #191818; */
    border: none;


}
.inputStyle::placeholder{
    color:#707070;
}
/* .inputStyle:active{
    outline: none;
    border-color: white;
}
.out:focus{
    border-color: white;
    outline: none;
} */

/* .profileinp{
}
.profileinp:active{
    
} */


/* for custom clock input */
/* .css-1x5jdmq{
    color: white !important
} */



/* for custom clock input */

