* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColour: #042e70;
}
body {
  margin: 0;
  /* font-family: Helvetica, Arial, sans-serif; */
  font-family: helhelvetica neue, helvetica, verdana, arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next,
.slick-prev {
  display: none !important;
}

html {
  font-size: 0.8rem;
}
.globleGradient {
  background-image: linear-gradient(45deg, #3d75c8, #df6011);
  -webkit-background-clip: text;
  color: transparent;
}
.css-1xevu2r-MuiStack-root {
  border-radius: 20px !important;
}
.globleVerticalLine {
  width: 1px; /* Line ki width ko adjust karein */
  height: 19px; /* Container ki height tak extend ho */
  background-color: #9f9f9f; /* Line ki color */
  margin: 0 10px; /* Line ke left-right margin ko adjust karein */
}
/* .globlescrollCards{
  overflow-x: scroll;
  width: 100%;
  width: 90vw;
} */
/* @media only screen and (max-width: 650px) {
  .globlescrollCards {
    overflow-x: scroll;
    flex-direction: row !important;
    justify-content: start !important;
  }
} */

@media only screen and (min-width: 640px) {
  html {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

/* fonts.css */
@font-face {
  font-family: "AftaSansThin-Regular";
  src: url("./fonts/AftaSansThin-Regular.otf") format("opentype");
}
@font-face {
  font-family: "AlexBrush-Regular";
  src: url("./fonts/AlexBrush-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Canterbury";
  src: url("./fonts/Canterbury.ttf") format("opentype");
}
@font-face {
  font-family: "DroidSerif";
  src: url("./fonts/DroidSerif.ttf") format("opentype");
}
@font-face {
  font-family: "Latin Modern Roman Bold";
  src: url("./fonts/lmroman5-bold.otf") format("opentype");
}

@font-face {
  font-family: "AlfaSlabOne";
  src: url("./fonts/AlfaSlabOne-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Courier Prime";
  src: url("./fonts/Courier Prime.ttf") format("opentype");
}
@font-face {
  font-family: "GOODDP";
  src: url("./fonts/GOODDP__.TTF") format("opentype");
}
@font-face {
  font-family: "GreatVibes";
  src: url("./fonts/GreatVibes-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "LiberationSans";
  src: url("./fonts/LiberationSans-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "lmroman5";
  src: url("./fonts/lmroman5-regular.otf") format("opentype");
}
@font-face {
  font-family: "monofonto";
  src: url("./fonts/monofonto rg.otf") format("opentype");
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "MouseMemoirs";
  src: url("./fonts/MouseMemoirs-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "NunitoSans";
  src: url("./fonts/NunitoSans-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "PORKYS";
  src: url("./fonts/PORKYS_.TTF") format("opentype");
}
/* @font-face {
  font-family: 'AftaSansThin-Regular';
  src: url('./fonts/AftaSansThin-Regular.otf') format('opentype');
} */
