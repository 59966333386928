.primary-btn {
  background-color: var(--primaryColour);
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
}

.primary-btn:hover {
  cursor: pointer;
}
